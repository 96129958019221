import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/en/chapter_3/sub_5/slide1';

import WrapperMobile from 'src/slides/mobile/en/chapter_3/sub_5/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from '../../../components/_shared/seo';

// desktop

const allSlides = [
  <Slide1 />,
];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Calendar of the Polish-Soviet War | The Battle of Warsaw" description="From the fights for Vilnius to Semyon Budyonny’s counter-offensive - the course of the Polish-Soviet War" lang="en" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

// mobile


const Mobile = () => (
  <>
    <SEO title="Calendar of the Polish-Soviet War | The Battle of Warsaw" description="From the fights for Vilnius to Semyon Budyonny’s counter-offensive - the course of the Polish-Soviet War" lang="en" />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
